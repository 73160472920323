
.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  flex: 1;
}

footer ul {
  list-style-type: none;
  padding: 0;
}

footer li {
  margin-bottom: 8px;
}

footer a {
  text-decoration: none;
  color: inherit;
}

footer a:hover {
  text-decoration: underline;
}

@media (max-width: 600px) {
  footer {
    text-align: center;
  }
}

.icon:hover {
  transform: translateY(-5px);
  transition: transform 0.3s;
}

.icon {
  transition: transform 0.3s;
}